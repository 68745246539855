/* eslint-disable indent */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  AnalyticsType,
  sendAnalyticEvent,
  sendUserIdToAnalytics,
} from '@src/components/web-analytic';
import {
  ClientCategory,
  Product,
  analyticEvents,
  miteStepsRoute,
} from '@src/constants';
import { useRequest } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { ConfirmAuthentificationResponse } from '@src/types';

interface Params {
  code?: string;
  confirmAuthenticationHandler?: () => void;
  confirmAuthenticationErrorHandler?: (message: string) => void;
}

export const useConfirmAuthentication = ({
  code,
  confirmAuthenticationHandler,
  confirmAuthenticationErrorHandler,
}: Params) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {
      stateAuth: { authenticationToken, confirmationId },
      stateAuthFlow: { authStep },
      stateUser: { profile, selectedProduct, preset },
    },
    dispatch,
  } = useContext(Store);

  const { isLoading, error, res, refetch } =
    useRequest<ConfirmAuthentificationResponse>(
      'authRequest',
      'post',
      '/v3/user/confirm-authentication',
      {
        code: code,
        confirmationId: confirmationId,
      },
      [code, confirmationId, authenticationToken],
      true,
      authenticationToken
    );

  useEffect(() => {
    if (!isLoading && !!res) {
      const { accessToken, csrfToken, refreshToken, isVip, userId, name } = res;

      dispatch({
        type: AuthActionTypes.SetAuthTokens,
        payload: {
          authorization: {
            accessToken,
            refreshToken,
            csrfToken,
          },
          isVip,
        },
      });

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: {
          ...profile,
          profile: {
            ...profile?.profile,
            firstName: name,
            userId,
          },
        },
      });

      sendUserIdToAnalytics(userId);
      localStorage.setItem('userId', userId);

      if (profile?.profile?.userId == userId && name) {
        let myTrackerLogin = analyticEvents.completeLogin;

        switch (selectedProduct) {
          case Product.SPORT:
            myTrackerLogin = analyticEvents.sportCompleteLogin;
            sendAnalyticEvent(
              analyticEvents.sportSignupCompleted,
              { sz: 'auth_signup_sport_completed' },
              AnalyticsType.adriver
            );
            break;
          case Product.APARTMENT:
            myTrackerLogin = analyticEvents.flatCompleteLogin;
            break;
          case Product.MITE:
            myTrackerLogin = analyticEvents.antimiteCompleteLogin;
            sendAnalyticEvent(
              analyticEvents.miteSignupCompleted,
              { sz: 'auth_signup_mite_completed' },
              AnalyticsType.adriver
            );
            break;
          case Product.PETS:
            myTrackerLogin = analyticEvents.petCompleteLogin;
            sendAnalyticEvent(
              analyticEvents.petsSignupCompleted,
              undefined,
              AnalyticsType.adriver
            );
            break;
        }

        sendAnalyticEvent(myTrackerLogin, {}, AnalyticsType.myTracker);

        if (preset) {
          sendAnalyticEvent(analyticEvents.signupPresetCompleted);
        } else {
          if (isVip) {
            localStorage.setItem('clientCategory', ClientCategory.VIP);
            sendAnalyticEvent(analyticEvents.signupCompleted, {
              registration: 'n',
              VIP: 'y',
            });
          } else {
            localStorage.setItem('clientCategory', ClientCategory.STANDART);
            sendAnalyticEvent(analyticEvents.signupCompleted, {
              registration: 'n',
            });
          }
        }
      }
      confirmAuthenticationHandler && confirmAuthenticationHandler();
    }
  }, [isLoading, res, confirmAuthenticationHandler]);

  useEffect(() => {
    if (error) {
      const e = error?.response?.status;
      let msg;

      if (e === 400) {
        if (error?.response?.data?.code === 'RATE_LIMIT_EXCEEDED') {
          dispatch({
            type: AuthActionTypes.SetConfirmationId,
            payload: undefined,
          });

          dispatch({
            type: AuthActionTypes.SetAuthenticationToken,
            payload: undefined,
          });

          dispatch({
            type: UserActionTypes.SetCachedPhoneNumber,
            payload: undefined,
          });

          dispatch({
            type: UserActionTypes.SetAuthenticationRefetchUid,
            payload: uuidv4(),
          });

          if (error?.response?.data?.unlockingTime) {
            msg = t('AUTH:errors.authorizationLimitExceeded');
          } else {
            dispatch({
              type: AuthActionTypes.SetAuthorizeFailState,
              payload: {
                title: t('AUTH:hints.letsTakeBreak'),
                subtitle: t('AUTH:labels.tryAgainLater'),
                refRoute:
                  selectedProduct === Product.MITE
                    ? miteStepsRoute
                    : `/${selectedProduct}`, //TODO: когда появится нулевой шаг - переделать роут для продуктов с нулевым шагом
                step: authStep,
              },
            });

            navigate('/authorize-fail');
          }
        }
      }

      confirmAuthenticationErrorHandler &&
        confirmAuthenticationErrorHandler(
          msg || t('AUTH:errors.incorrectCodeFromSms') || ''
        );
    }
  }, [error, confirmAuthenticationErrorHandler]);

  return refetch;
};
