/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable indent */
import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  HorizontalAlignDirection,
  RadioCardGroup,
  TextAlignDirection,
} from '@pulse-web-ui/radio-card-group';

import { FullWidthWrapper } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { Product, analyticEvents } from '@src/constants';
import { useHandlePressKey } from '@src/hooks';
import { Store, UserActionTypes } from '@src/store';
import { KeyCode } from '@src/types';
import { getProductForAnalytic, getProductItems } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import {
  AuthPageTitle,
  AuthPageWrapper,
  StyledFooter,
  StyledRadioCardGroupBox,
} from './authorization-pages.styles';

export const SubscriptionChoice: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const productItems = getProductItems();
  const {
    state: {
      stateAuth: { authTokens },
      stateUser: { selectedProduct, cachedProduct },
    },
    dispatch,
  } = useContext(Store);

  const [product, setProduct] = useState<Product | undefined | string>(
    selectedProduct || cachedProduct
  );

  useEffect(() => {
    localStorage.setItem(
      'selectedProduct',
      selectedProduct ?? (product as string)
    );
  }, [product, selectedProduct]);

  useEffect(() => {
    if (product !== selectedProduct) {
      dispatch({
        type: UserActionTypes.SetPromoCodeFailedMessage,
        payload: undefined,
      });
    }
  }, [product, selectedProduct]);

  const handleKeyPressEnter = () => {
    if (product !== undefined) {
      submitPage();
    }
  };

  const submitPage = () => {
    const promiseSubmit = Promise.resolve(() => {});

    promiseSubmit
      .then(() => {
        sendAnalyticEvent(analyticEvents.webProductsSelected, {
          product: getProductForAnalytic(product),
        });
        return dispatch({
          type: UserActionTypes.SetSelectedProduct,
          payload: product as Product,
        });
      })
      .then(() => {
        if (authTokens?.authorization.accessToken) {
          return navigate(`/${product}`);
        } else return navigate('/login');
      });
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, product);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProduct(e.target.value);
  };

  const footer = useMemo(
    () => (
      <StyledFooter>
        <Button
          variant="primary"
          disabled={product === undefined}
          label={t('COMMON:buttons.continue') || ''}
          onClick={submitPage}
        />
      </StyledFooter>
    ),
    [product]
  );

  return (
    <BaseLayout verticalAlignment={false}>
      <AuthPageWrapper>
        <AuthPageTitle>
          {t('AUTH:headers.whichSubscriptionInterested')}
        </AuthPageTitle>
        <FullWidthWrapper>
          <StyledRadioCardGroupBox>
            <RadioCardGroup
              value={selectedProduct}
              name="isSubscriptionCardGroup"
              onChange={handleChange}
              items={productItems}
              textAlign={TextAlignDirection.LEFT}
              horizontalAlign={HorizontalAlignDirection.CENTER}
            />
          </StyledRadioCardGroupBox>
        </FullWidthWrapper>
        <FullWidthWrapper>{footer}</FullWidthWrapper>
      </AuthPageWrapper>
    </BaseLayout>
  );
};
