/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { MoneyRangeInput } from '@pulse-web-ui/range-input';

import {
  AdaptiveHeadersWrapper,
  AdaptiveSliderListWrapper,
  Container,
  HeaderAdaptive1WithSubTitle,
  HeaderAdaptive5,
  Skeleton,
  SumWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo, IflSumPerMonth } from '@src/features';
import { useHandlePressKey, useNextStep, useRequest } from '@src/hooks';
import { Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode } from '@src/types';
import { currencyRuLocaleWithoutFraction } from '@src/utils';

import { useNsDraft } from './hooks';

export const FormInsuranceSum: FC = memo(() => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormNS: {
        insuranceSum,
        insuranceProduct,
        selectedMainRisks = [],
        selectedAdditionalRisks = [],
        numberInsurePersons: { numberChildren, numberAdults, numberElderly },
        promoCodeApplyed,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const risks = useMemo(
    () => [...selectedMainRisks, ...selectedAdditionalRisks],
    [selectedMainRisks, selectedAdditionalRisks]
  );

  const persons = useMemo(
    () => [
      ...Array(numberChildren).fill({ ageMin: '1' }),
      ...Array(numberAdults).fill({ ageMin: '18' }),
      ...Array(numberElderly).fill({ ageMin: '70' }),
    ],
    [numberChildren, numberAdults, numberElderly]
  );

  const schema = useMemo(
    () =>
      yup.object({
        editedSum: yup
          .number()
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          )
          .typeError(t('COMMON:errors.mustEnterNumber') || '')
          .min(
            Number(insuranceProduct?.minProductLimit),
            `${t(
              'COMMON:errors.amountNotLess'
            )} ${currencyRuLocaleWithoutFraction(
              Number(insuranceProduct?.minProductLimit)
            )} ₽`
          )
          .max(
            Number(insuranceProduct?.maxProductLimit),
            `${t(
              'COMMON:errors.amountNoMore'
            )} ${currencyRuLocaleWithoutFraction(
              Number(insuranceProduct?.maxProductLimit)
            )} ₽`
          )
          .integer(t('COMMON:errors.sumMustBeIntegerValue') || '')
          .required()
          .test(
            'In diapason?',
            t('COMMON:errors.amountCannotBeFrom') || '',
            (value) => value! > 49_999 || value! < 1
          ),
      }),
    [insuranceProduct?.minProductLimit, insuranceProduct?.maxProductLimit]
  );

  const {
    control,
    formState: { errors, isValid },
    getValues,
    trigger,
    setValue,
  } = useForm<{ editedSum: number }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      editedSum: insuranceSum,
    },
  });

  useEffect(() => {
    if (!insuranceSum) {
      const defaultInsuranceSum =
        insuranceProduct?.defaultInsuranceSum || 20_0000;
      dispatch({
        type: NSActionTypes.SetInsuranceSum,
        payload: defaultInsuranceSum,
      });
      setValue('editedSum', defaultInsuranceSum);
    }
  }, []);

  const updateInsuranceSum = async () => {
    const valid = await trigger('editedSum');

    if (valid) {
      dispatch({
        type: NSActionTypes.SetInsuranceSum,
        payload: Number(getValues('editedSum')),
      });
    }
  };

  const validatePage = useCallback(() => !errors?.editedSum?.message, [errors]);

  const { isLoading, error, res, refetch, isFetching } = useRequest(
    'formNSGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      risks,
      productCode: insuranceProduct?.code,
      insuranceSum:
        Number(insuranceSum) ||
        Number(insuranceProduct?.defaultInsuranceSum) ||
        20_000,
      returnMinDuration: true,
      accidents: {
        persons,
      },
      promoCode: promoCodeApplyed ? promoCodeApplyed : undefined,
    },
    [
      risks,
      insuranceProduct?.code,
      insuranceSum,
      numberChildren,
      numberAdults,
      numberElderly,
      promoCodeApplyed,
    ],
    true
  );

  const {
    res: totalLimitsRes,
    isLoading: isTotalLimitsLoading,
    error: totalLimitsError,
    refetch: refetchTotalLimits,
  } = useRequest<{
    totalMinLimit: string;
    totalMaxLimit: string;
  }>(
    'getNsTotalLimits',
    'get',
    `/v3/references/total-limits/${insuranceProduct?.code}`,
    {},
    [insuranceProduct?.code],
    false,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (!isTotalLimitsLoading && totalLimitsRes && insuranceProduct) {
      dispatch({
        type: NSActionTypes.SetInsuranceProduct,
        payload: {
          ...insuranceProduct,
          minProductLimit: totalLimitsRes.totalMinLimit,
          maxProductLimit: totalLimitsRes.totalMaxLimit,
        },
      });
      if (
        insuranceSum &&
        Number(insuranceSum) > Number(totalLimitsRes.totalMaxLimit)
      ) {
        dispatch({
          type: NSActionTypes.SetInsuranceSum,
          payload: Number(totalLimitsRes.totalMaxLimit),
        });
        setValue('editedSum', Number(totalLimitsRes.totalMaxLimit));
      }
    }
  }, [totalLimitsRes, isTotalLimitsLoading, insuranceSum]);

  useNextStep(validatePage);
  useNsDraft();

  const handleKeyPressEnter = () => {
    if (Object.keys(errors).length === 0) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [errors]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        totalLimitsRes &&
        Number(insuranceSum) <= Number(totalLimitsRes.totalMaxLimit)
      ) {
        refetch();
        sendAnalyticEvent(analyticEvents.coverageSumChangeAccident);
      }
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [insuranceSum, totalLimitsRes]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !!errors?.editedSum?.message || isFetching,
    });
  }, [errors, isValid, isFetching]);

  if (isTotalLimitsLoading) return <Skeleton />;

  if (error || totalLimitsError)
    return (
      <GlobalErrorInfo
        pending={isLoading || isTotalLimitsLoading}
        retrayHandler={error ? refetch : refetchTotalLimits}
      />
    );

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <HeaderAdaptive1WithSubTitle>
          {t('NS_FORM:headers.chooseInsuredSum')}
        </HeaderAdaptive1WithSubTitle>
        <HeaderAdaptive5>
          {t('NS_FORM:hints.subscriptionWillBeCalculated')}
        </HeaderAdaptive5>
      </AdaptiveHeadersWrapper>
      {insuranceProduct && insuranceSum && (
        <>
          <AdaptiveSliderListWrapper marginBottom={48}>
            <Controller
              name="editedSum"
              control={control}
              render={({ field }) => (
                <MoneyRangeInput
                  isPriceFormatted
                  id={insuranceProduct.code}
                  label={t('COMMON:labels.coverAmount') || ''}
                  value={field.value}
                  min={Number(insuranceProduct.minProductLimit) || 0}
                  max={Number(insuranceProduct.maxProductLimit) || 0}
                  step={Number(insuranceProduct.step) || 0}
                  onChange={(value: number) => {
                    field.onChange(value);
                    updateInsuranceSum();
                  }}
                  error={!!errors?.editedSum?.message}
                  helperErrorText={errors?.editedSum?.message}
                />
              )}
            />
          </AdaptiveSliderListWrapper>
          <SumWrapper>
            <IflSumPerMonth
              isLoading={isFetching || !!error}
              sumPerMonth={Math.ceil(Number(res?.prices[0]?.premiumAndDelta))}
              sumPromoPerMonth={
                res?.prices[0]?.premiumAndDeltaPromo &&
                Math.ceil(Number(res?.prices[0].premiumAndDeltaPromo))
              }
            />
          </SumWrapper>
        </>
      )}
    </Container>
  );
});
