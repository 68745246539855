import { YMaps } from '@pbe/react-yandex-maps';
import React, { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Product } from '@src/constants';
import { StepWizardForm } from '@src/features';
import { useComponentsConfig, useResetFlatSteps } from '@src/hooks';
import {
  AuthActionTypes,
  PresetSettingActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { ComponentsConfigType } from '@src/types';

import { useAnalyticsPets } from './hooks';

export const PetsForm: FC = () => {
  const {
    state: {
      stateWizard: { currentStep },
      stateFormPets: { presetData },
      statePresetSetting: { isPresetSettingDisplayed },
    },
    dispatch,
  } = useContext(Store);
  const { components } = useComponentsConfig(
    Product.PETS,
    !!presetData ? ComponentsConfigType.MAIN : ComponentsConfigType.DEFAULT
  );
  const navigate = useNavigate();

  useAnalyticsPets();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      navigate('/login');
    } else {
      navigate(-1);
    }
  };
  const maxStep = components?.length || 0;

  useEffect(() => {
    if (isPresetSettingDisplayed) {
      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: maxStep,
      });
      dispatch({
        type: PresetSettingActionTypes.SetIsPresetSettingDisplayed,
        payload: false,
      });
    }
  }, []);

  return (
    <YMaps>
      <StepWizardForm
        maxStep={maxStep}
        useResetSteps={useResetFlatSteps}
        goBack={goBack}
      >
        {components?.map((Component, index) => {
          if (currentStep === index + 1) {
            return (
              <React.Fragment key={index}>
                <Component />
              </React.Fragment>
            );
          }
          return null;
        })}
      </StepWizardForm>
    </YMaps>
  );
};
