/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { NewRadio, NewRadioGroup } from '@pulse-web-ui/new-radio-group';
import { useTheme } from '@pulse-web-ui/theme';

import {
  Container,
  FormLabel,
  FormSubTitle,
  InfoWrapper,
  RadioDescriptionRender,
  RadioLabelRender,
  Skeleton,
  WarnigText,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  DEFAULT_FIAS_ID,
  FRACTION_TWO,
  PRODUCT_VERSION_TWO,
  analyticEvents,
  durationDescription,
  insuranceProductsCode,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useNextStep, useRequests } from '@src/hooks';
import {
  AuthActionTypes,
  OrderActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { IFLFlatActionTypes } from '@src/store/ifl-flat';
import { WizardActionTypes } from '@src/store/wizard';
import {
  FormPromoProps,
  KeyCode,
  OrderItemType,
  OrderItemValueType,
  SelectedDuration,
} from '@src/types';
import {
  checkDateBefore,
  convertDateByTimeZone,
  currencyRuLocaleWithoutFraction,
  getAdditionalRisksToString,
  getFormPeriodLabels,
  getMainRisksToString,
  numFormat,
  setPaymentPageTexts,
  subObjectConverter,
} from '@src/utils';

import {
  formInsurancePeriodDefaultValues,
  formInsurancePeriodSchema,
} from '../../schemas';
import {
  ContentWrapper,
  PromoSubmitButton,
  PromoTextInput,
  PromoWrapper,
} from './form-insurance-period.styles';
import { useIflFlatDraft, useIflOrderFlatData } from './hooks';

export const FormInsurancePeriod: FC = () => {
  const theme: any = useTheme();
  const [isInputActive, setIsInputActive] = useState(false);
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLFlat: {
        promoCode,
        promoCodeApplyed,
        risks,
        insuranceSum,
        insuranceSubproducts,
        selectedDuration,
        selectedRegion,
        selectedDate,
        selectedIProduct,
        getPricesFull,
        dadaValue,
      },
      stateAuth: { authTokens, displayPhoneNumber },
      stateUser: { profile, promoCodeFailedMessage },
    },
    dispatch,
  } = useContext(Store);

  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormPromoProps>(
    formInsurancePeriodDefaultValues
  );
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [orderArray, setOrderArray] = useState<OrderItemType[]>([]);
  const [autoInitPromo, setAutoInitPromo] = useState(false);
  const [localPromo, setLocalPromo] = useState<string | undefined>();
  const formPeriodLabels = getFormPeriodLabels();

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflStep5Period);
    sendAnalyticEvent(analyticEvents.iflFlatStep5Period);
  }, []);

  const navigate = useNavigate();
  useIflOrderFlatData();
  useIflFlatDraft();

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrder,
      payload: orderArray,
    });
  }, [orderArray]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    getValues,
    watch,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      promoCode:
        promoCodeApplyed && getPricesFull?.isSuccessfulPromo
          ? promoCodeApplyed
          : promoCode,
    },
  });

  useEffect(() => {
    if (selectedDate !== (null || undefined)) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: IFLFlatActionTypes.SetPromoCode,
        payload: value.promoCode,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    if (promoCodeApplyed && getPricesFull?.isSuccessfulPromo) {
      setLocalPromo(promoCodeApplyed);
      setAutoInitPromo(true);
    }
  }, []);

  const validatePage = useCallback(() => {
    navigate('/order-detail');

    return false;
  }, []);

  useNextStep(validatePage);
  useIflFlatDraft();

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: IFLFlatActionTypes.SetSelectedDate,
      payload: newDate,
    });
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
    sendAnalyticEvent(analyticEvents.dateStartRealty);
  };

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: IFLFlatActionTypes.SetSelectedDuration,
      payload: val,
    });

    sendAnalyticEvent(analyticEvents.periodSelectRealty, {
      period: durationDescription[val],
    });
  };

  const {
    isLoading,
    isError,
    res: [getPricesData, getInsuranceLimitStartData],
    refetchAll,
  } = useRequests([
    {
      key: 'formIFLGetPricesFull',
      method: 'post',
      url: '/v1/subscription/get-prices',
      request: {
        risks: risksArray,
        productCode: selectedIProduct?.code,
        insuranceSum: Number(insuranceSum),
        personProperties: {
          subObjects:
            insuranceSubproducts?.subObjects &&
            subObjectConverter(insuranceSubproducts.subObjects),
          address: {
            regionCode: selectedRegion?.region,
            address: dadaValue?.value || '',
            addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
          },
        },
        returnMinDuration: false,
        promoCode: autoInitPromo ? localPromo : undefined,
      },
      deps: [
        risks,
        insuranceSubproducts,
        localPromo,
        autoInitPromo,
        dadaValue?.value,
        dadaValue?.data?.fias_id,
      ],
    },
    {
      key: 'formIFLGetInsuranceLimitStart',
      method: 'get',
      url: `/v3/references/insurance-limit-start/${insuranceProductsCode.flat}/${PRODUCT_VERSION_TWO}`,
      deps: [risks],
    },
  ]);

  useEffect(() => {
    if (
      selectedDate &&
      getInsuranceLimitStartData &&
      (checkDateBefore(
        new Date(selectedDate),
        new Date(getInsuranceLimitStartData.data?.startDate)
      ) ||
        checkDateBefore(
          new Date(getInsuranceLimitStartData.data?.endDate),
          new Date(selectedDate)
        ) ||
        dateEqual)
    ) {
      setSelectedDate(new Date(getInsuranceLimitStartData.data?.startDate));
    }
  }, [getInsuranceLimitStartData, dateEqual]);

  useEffect(() => {
    if (isLoading) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });

      setIsInputActive(false);
    }
  }, [isLoading]);

  const handleKeyPressEnter = () => {
    if (isInputActive) {
      submitPromoCode();
    } else {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [isInputActive]);

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderPageTitle,
      payload: t('IFL_FLAT_FORM:headers.pulseApartment') || '',
    });
  }, []);

  useEffect(() => {
    setPaymentPageTexts(
      t('COMMON:hints.somethingHappensApartment'),
      selectedDate
    );

    let selectedPrice = getPricesFull?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0]?.premiumAndDelta;

    let selectedPromoPrice = getPricesFull?.prices.filter(
      (item) => item.duration === selectedDuration
    )[0]?.premiumAndDeltaPromo;

    if (!selectedPrice) {
      const defaultSelectedDuration = getPricesData?.data?.prices[0]?.duration;

      dispatch({
        type: IFLFlatActionTypes.SetSelectedDuration,
        payload: defaultSelectedDuration,
      });

      selectedPrice = getPricesFull?.prices.filter(
        (item) => item.duration === defaultSelectedDuration
      )[0]?.premiumAndDelta;

      selectedPromoPrice = getPricesFull?.prices.filter(
        (item) => item.duration === defaultSelectedDuration
      )[0]?.premiumAndDeltaPromo;
    }

    let label = `${
      selectedPrice
        ? currencyRuLocaleWithoutFraction(Number(selectedPrice), FRACTION_TWO)
        : ''
    } ${formPeriodLabels.get(selectedDuration!)?.label}`;
    let subLabel = '';
    const isSuccessfulPromo = getPricesFull?.isSuccessfulPromo;

    if (!!selectedPromoPrice) {
      label = `${currencyRuLocaleWithoutFraction(
        Number(selectedPromoPrice),
        FRACTION_TWO
      )} ${formPeriodLabels.get(selectedDuration!)?.promoLabel}`;
      subLabel =
        `${
          formPeriodLabels.get(selectedDuration!)?.promoSubLabel
        } ${currencyRuLocaleWithoutFraction(
          Number(selectedPrice),
          FRACTION_TWO
        )} ₽` || '';
    }

    const name = [
      profile?.profile?.lastName,
      profile?.profile?.firstName,
      profile?.profile?.middleName !== '' ? profile?.profile?.middleName : null,
    ].join(' ');

    const checkForFailedPromoCode = () => {
      if (promoCodeFailedMessage) {
        return t('COMMON:errors.promoCodeNotApplied');
      }
      return isSuccessfulPromo === undefined ||
        isSuccessfulPromo === null ||
        promoCode === '' ||
        (isSuccessfulPromo && !selectedPromoPrice)
        ? ''
        : t('COMMON:errors.promoCodeNotApplied') || '';
    };

    const tempOrderArray: OrderItemType[] = [
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.periodOfPayment'),
        value: label,
        secondsValue: subLabel,
        successValue:
          isSuccessfulPromo && selectedPromoPrice
            ? t('COMMON:success.promoCodeApplied') || ''
            : '',
        failedValue: checkForFailedPromoCode(),
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.addressOfObject'),
        value: String(dadaValue?.value),
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.mainRisks'),
        value: getMainRisksToString(risks),
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.additionalRisks'),
        value:
          getAdditionalRisksToString(risks) ||
          t('COMMON:placeholders.notSelected') ||
          '',
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.coverAmount'),
        value: `${numFormat(insuranceSum)} ₽`,
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.subscriptionWillStart'),
        value: `с ${selectedDate?.toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}`,
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.insurant'),
        value: name,
      },
      {
        type: OrderItemValueType.SIMPLE,
        title: t('COMMON:labels.phoneOfNumber'),
        value: displayPhoneNumber,
      },
    ];

    setOrderArray(tempOrderArray);
  }, [
    isLoading,
    getPricesFull?.prices,
    selectedDuration,
    dadaValue,
    risks,
    insuranceSum,
    selectedDate,
    displayPhoneNumber,
    profile,
  ]);

  useEffect(() => {
    if (!isLoading && getPricesData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: IFLFlatActionTypes.SetGetPricesFull,
        payload: getPricesData.data,
      });
      if (selectedDuration === null) {
        dispatch({
          type: IFLFlatActionTypes.SetSelectedDuration,
          payload: getPricesData?.data?.prices[0]?.duration,
        });
      }
      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        formData.promoCode !== ''
      ) {
        setError('promoCode', {
          type: 'string',
          message: 'COMMON:errors.promoCodeNotValid',
        });

        dispatch({
          type: UserActionTypes.SetPromoCodeFailedMessage,
          payload: t('COMMON:errors.promoCodeNotValid') || '',
        });
      }

      const isPromoCodeAppliedSuccessfully =
        getPricesData.data?.isSuccessfulPromo && promoCode !== '';

      let isButtonHasToBeDisabled = true;

      if (isPromoCodeAppliedSuccessfully) {
        isButtonHasToBeDisabled = true;
      }

      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        promoCode !== ''
      ) {
        isButtonHasToBeDisabled = false;
      }

      if (
        getPricesData?.data?.isSuccessfulPromo === undefined &&
        promoCode !== '' &&
        !autoInitPromo
      ) {
        isButtonHasToBeDisabled = false;
      }
      if (
        getPricesData?.data?.isSuccessfulPromo &&
        promoCode !== '' &&
        !promoCodeApplyed
      ) {
        dispatch({
          type: IFLFlatActionTypes.SetPromoCodeApplyed,
          payload: promoCode,
        });
      }

      setSubmitPromoDisabled(isButtonHasToBeDisabled);
    }
  }, [isLoading, getPricesData.data]);

  useEffect(() => {
    if (!isLoading && getInsuranceLimitStartData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      setMinDate(new Date(getInsuranceLimitStartData.data?.startDate));
      setMaxDate(new Date(getInsuranceLimitStartData.data?.endDate));
      if (
        JSON.stringify(getInsuranceLimitStartData.data?.startDate) ===
        JSON.stringify(getInsuranceLimitStartData.data?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(
        selectedDate || new Date(getInsuranceLimitStartData.data?.startDate)
      );
    }
  }, [isLoading, getInsuranceLimitStartData.data]);

  useEffect(() => {
    if (authTokens?.authorization?.accessToken) {
      reset();
    }
  }, [authTokens?.authorization?.accessToken]);

  useEffect(() => {
    if (getPricesData?.data?.isSuccessfulPromo === false && promoCode !== '') {
      setError('promoCode', {
        type: 'string',
        message: 'COMMON:errors.promoCodeNotValid',
      });
      dispatch({
        type: IFLFlatActionTypes.SetPromoCodeApplyed,
        payload: undefined,
      });
    }
  }, [isLoading, getPricesData?.data, promoCode]);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === promoCode);
  };

  const submitPromoCode = handleSubmit((data) => {
    dispatch({
      type: IFLFlatActionTypes.SetPromoCode,
      payload: data.promoCode,
    });

    dispatch({
      type: UserActionTypes.SetPromoCodeFailedMessage,
      payload: undefined,
    });

    dispatch({
      type: IFLFlatActionTypes.SetPromoCodeApplyed,
      payload: undefined,
    });

    setFormData(data);
    setAutoInitPromo(true);
    setLocalPromo(promoCode);
  });

  const getLabel = () => {
    const { promoCode: statePromoCode } = getValues();
    const isPromoCodeAppliedSuccessfully =
      getPricesData?.data?.isSuccessfulPromo;

    return isPromoCodeAppliedSuccessfully && promoCode === statePromoCode
      ? t('COMMON:success.applied')
      : t('COMMON:success.apply');
  };

  if (isLoading) return <Skeleton />;

  if (isError) {
    const e = (isError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration') || '',
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }
    return <GlobalErrorInfo retrayHandler={refetchAll} />;
  }

  return (
    <Container>
      <FormLabel marginBottom={32}>
        {t('COMMON:headers.paymentOfPeriod')}
      </FormLabel>
      <ContentWrapper>
        <NewRadioGroup
          name="duration"
          onChange={onRadioSelector}
          selectedValue={selectedDuration}
        >
          {getPricesFull?.prices &&
            getPricesFull?.prices.map((item) => (
              <NewRadio
                key={`${item.duration}-key`}
                value={item.duration}
                label={RadioLabelRender(item)}
                description={RadioDescriptionRender(item)}
              />
            ))}
        </NewRadioGroup>
      </ContentWrapper>

      <ContentWrapper>
        <PromoWrapper>
          <PromoTextInput>
            <Controller
              control={control}
              name="promoCode"
              render={({ field: { onChange, value }, fieldState }) => (
                <HelperText
                  status={
                    fieldState.error || promoCodeFailedMessage
                      ? 'error'
                      : 'success'
                  }
                  message={
                    (errors.promoCode?.message &&
                      t(errors.promoCode.message)) ||
                    promoCodeFailedMessage ||
                    (getPricesData.data?.isSuccessfulPromo && promoCode !== ''
                      ? t('COMMON:hints.discountForFirstPeriod')
                      : '')
                  }
                >
                  <Input
                    label={t('COMMON:labels.promoCode') || ''}
                    value={value}
                    onChange={(val) => {
                      checkSubmitState(val);
                      return onChange(val);
                    }}
                    error={!!errors.promoCode || !!promoCodeFailedMessage}
                    onFocus={() => setIsInputActive(true)}
                    onBlur={() => setIsInputActive(false)}
                    disabled={isLoading}
                  />
                </HelperText>
              )}
            />
          </PromoTextInput>
          <PromoSubmitButton>
            <Button
              label={getLabel()}
              onClick={submitPromoCode}
              disabled={submitPromoDisabled}
              variant="secondary-2"
              adaptiveWidth
            />
          </PromoSubmitButton>
        </PromoWrapper>
      </ContentWrapper>

      <FormSubTitle>{t('COMMON:labels.subscriptionActive')}</FormSubTitle>
      <HelperText message="" status="default" messagePosition="up">
        {isLoading ? (
          <Skeleton />
        ) : dateEqual ? (
          <InfoWrapper>
            <Info width={24} color={theme.colors.icon.primary} />
            <WarnigText>
              {t('COMMON:labels.subscriptionWillStart')}{' '}
              {minDate.toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </WarnigText>
          </InfoWrapper>
        ) : (
          <Datepicker
            onChange={setSelectedDate}
            selected={selectedDate}
            minDate={convertDateByTimeZone(minDate)}
            maxDate={convertDateByTimeZone(maxDate)}
          />
        )}
      </HelperText>
    </Container>
  );
};
