/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useContext } from 'react';
import type { AxiosRequestHeaders } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@src/store';
import type { Method, Url } from '@src/types';
import { axiosInstance } from '@src/utils';
import { PlatformType, AuthEndpoints } from '@src/constants/request-data';

const customFetch = async <T, D>({
  method,
  url,
  request,
  config,
}: {
  method: Method;
  url: Url;
  request?: D;
  config: AxiosRequestConfig<D>;
}) => {
  if (method === 'get') {
    return axiosInstance.get<D, AxiosResponse<T>>(url, config);
  }

  return axiosInstance[method]<D, AxiosResponse<T>>(url, request, config);
};

export const useQueryFunction = <T = any, D = any>(
  method: Method,
  url: Url,
  request?: D,
  authToken?: string,
  requestConfig?: AxiosRequestConfig<D>,
) => {
  const {
    state: {
      stateOrder: { themeValue },
    },
  } = useContext(Store);

  let headers: AxiosRequestHeaders = {
    'X-Process-ID': uuidv4(),
    'Content-type': 'application/json',
  };

  if ((<any>Object).values(AuthEndpoints).includes(url)) {
    headers = {
      ...headers,
      'X-Platform-Type': PlatformType,
    };
  }

  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  if (authToken) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  const themedHeaders = themeValue ? {
    ...authHeaders,
    'X-personalization': themeValue,
  } : undefined;

  let config = {
    headers: themeValue ? themedHeaders : (authToken ? authHeaders : headers),
    ...requestConfig,
  };

  if (AuthEndpoints.CONFIRMAUTH.includes(url)) {
    config = {
      withCredentials: true,
      ...config,
    };
  }

  const queryFn = async (data: any) => {
    return customFetch<T, D>({ method, url, request: request || data, config: requestConfig ? requestConfig : config });
  };

  return queryFn;
};
