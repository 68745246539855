export enum AuthActionTypes {
  SetPhoneNumber = 'AUTH/SET_PHONE_NUMBER',
  SetDisplayPhoneNumber = 'AUTH/SET_DISPLAY_PHONE_NUMBER',
  SetAuthenticationToken = 'AUTH/SET_AUTHENTICATION_TOKEN',
  SetConfirmationId = 'AUTH/SET_CONFIRMATION_ID',
  SetAuthorizeFailState = 'AUTH/SET_AUTHORIZE_FAIL_STATE',
  SetAuthorizeRefRoute = 'AUTH/SET_AUTHORIZE_REF_ROUTE',
  SetAuthTokens = 'AUTH/SET_AUTH_TOKENS',
  SetAuthorizeConfirmState = 'AUTH/SET_AUTHORIZE_CONFIRM_STATE',
}

export type AuthorizePageState = {
  title: string;
  subtitle: string;
  refRoute: string;
  step?: number;
};

export type AuthTokens = {
  authorization: {
    accessToken: string;
    refreshToken?: string;
    csrfToken?: string;
  };
  isVip: boolean;
};
