/* eslint-disable indent */
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import { PageBackButton } from '@src/common-components/button';
import { ArrowLeftIcon2 } from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { Product, analyticEvents, iflRoute, nsRoute } from '@src/constants';
import { Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';

import { PhoneVerification } from '../phone-verification';
import { WizardProps } from './index';
import { ContentArea, NavFooter } from './step-wizard-form.styles';
import { NavHeader } from './step-wizard-header';

export const StepWizardForm: FC<WizardProps> = ({
  maxStep,
  useResetSteps,
  children,
  nextButtonLabel,
  prevButtonLabel,
  goBack,
  onNextStep,
  isSettingPage,
}) => {
  const {
    state: {
      stateWizard: { currentStep, fwNavDisabled },
      stateAuth: { authorizeRefRoute },
      stateAuthFlow: { authStep, sepScrAuth, authFlowParams },
      stateUser: { isPureSubscription, selectedProduct },
      stateAuthFlow: { initAuthState },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (useResetSteps) {
    useResetSteps();
  }

  const setStep = (step: number) => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: step,
    });
  };

  const setNextStep = useCallback(() => {
    if (onNextStep instanceof Function) {
      onNextStep();
      return;
    }

    if (sepScrAuth && currentStep === authStep) {
      const step = currentStep === maxStep ? currentStep : currentStep + 1;
      const nextRoute = currentStep === maxStep ? '/order-detail' : pathname;

      navigate('/login', { state: { currentStep: step, nextRoute } });
      return;
    }

    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: true,
    });
  }, [onNextStep, sepScrAuth, currentStep, authStep, maxStep, pathname]);

  const onClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep,
        });
        break;
      // TODO: добавить аналитику для питомцев
    }

    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: false,
    });

    if (currentStep === 1 && goBack) {
      goBack();
    } else {
      setStep(currentStep - 1);
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetLastStep,
      payload: maxStep,
    });
  }, []);

  const showButton =
    isPureSubscription ||
    currentStep > 1 ||
    selectedProduct === Product.MITE ||
    isSettingPage;

  return (
    <>
      {showButton && (
        <PageBackButton
          variant="text"
          icon={<ArrowLeftIcon2 />}
          onClick={onClickBack}
        >
          {prevButtonLabel || t('COMMON:buttons.back')}
        </PageBackButton>
      )}
      <NavHeader currentStep={currentStep} maxStep={maxStep} />
      <ContentArea>{children}</ContentArea>
      <NavFooter>
        {currentStep === authStep && !sepScrAuth && !isSettingPage ? (
          <PhoneVerification
            onCodeSuccess={setNextStep}
            isSubmitButtonDisabled={fwNavDisabled}
          />
        ) : (
          <Button
            variant="primary"
            onClick={setNextStep}
            disabled={fwNavDisabled}
          >
            {nextButtonLabel || t('COMMON:buttons.continue')}
          </Button>
        )}
      </NavFooter>
    </>
  );
};
