import { memo, useContext, useEffect } from 'react';
import type { FC } from 'react';

import {
  AuthActionTypes,
  AuthFlowActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { refreshToken } from '@src/utils';

export const RefreshBox: FC = memo(() => {
  const {
    state: {
      stateAuth: { authTokens },
      stateUser: { profile },
      stateAuthFlow: { initAuthState },
    },
    dispatch,
  } = useContext(Store);

  useEffect(() => {
    {
      if (
        !!localStorage.getItem('csrfToken') &&
        localStorage.getItem('csrfToken') !== null &&
        !authTokens?.authorization?.csrfToken
      ) {
        refreshToken()
          .then((e) => {
            const {
              data: {
                authorization: { csrfToken, name, phone, userId },
              },
            } = e;
            localStorage.removeItem('csrfToken');

            if (csrfToken) {
              localStorage.setItem('csrfToken', csrfToken);
            }
            dispatch({
              type: AuthActionTypes.SetAuthTokens,
              payload: e?.data,
            });
            dispatch({
              type: AuthActionTypes.SetPhoneNumber,
              payload: (phone as string) || '',
            });
            return dispatch({
              type: UserActionTypes.SetProfile,
              payload: {
                ...profile,
                profile: {
                  ...profile?.profile,
                  firstName: name || null,
                  userId: userId || null,
                  phone: phone || null,
                },
              },
            });
          })
          .then(() => {
            if (initAuthState) {
              dispatch({
                type: AuthFlowActionTypes.SetInitAuthState,
                payload: false,
              });
            }
          })
          .catch((error) => {
            const { response } = error;

            if (response?.status === 401) {
              localStorage.removeItem('csrfToken');
            }
            if (initAuthState) {
              dispatch({
                type: AuthFlowActionTypes.SetInitAuthState,
                payload: false,
              });
            }
            return null;
          });
      } else {
        if (initAuthState) {
          dispatch({
            type: AuthFlowActionTypes.SetInitAuthState,
            payload: false,
          });
        }
      }
    }
  }, []);

  return null;
});
