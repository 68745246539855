/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ValidationError } from 'yup';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { MoneyRangeInput } from '@pulse-web-ui/range-input';

import {
  AdaptiveHeadersWrapper,
  AdaptivePerMonthFooter,
  AdaptiveSliderListWrapper,
  Container,
  HeaderAdaptive1WithSubTitle,
  HeaderAdaptive5,
  IflSumPerMonthContainer,
  LinkContainer,
  Skeleton,
  SumWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { DEFAULT_FIAS_ID, analyticEvents } from '@src/constants';
import { GlobalErrorInfo, IflSumPerMonth, TotalSum } from '@src/features';
import { IflSumPerMonthError } from '@src/features/ifl-sum-per-month';
import {
  useHandlePressKey,
  useMergeSublimits,
  useNextStep,
  useRequest,
} from '@src/hooks';
import { Store } from '@src/store';
import { IFLFlatActionTypes } from '@src/store/ifl-flat';
import type { InsuranceSubproducts } from '@src/store/ifl-flat';
import { WizardActionTypes } from '@src/store/wizard';
import { InsuranceSubProduct, KeyCode } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  numBetween,
  numFormat,
  subObjectConverter,
} from '@src/utils';

import { useIflFlatDraft } from './hooks';

let schemaObj: Record<string, any> = {};
let defaults: Record<string, number> = {};

export const FormInsuranceSum: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLFlat: {
        insuranceSubproducts,
        selectedIProduct,
        selectedRegion,
        risks,
        fieldWithEmptyDependencies,
        emptyDependencies,
        dadaValue,
        getSubobjectsRefetchUid,
        currentSum,
        currentSumMinLimit,
        currentSumMaxLimit,
        currentSumErr,
        promoCodeApplyed,
      },
      stateWizard: { currentStep },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const [schema, setSchema] = useState<any>();
  const navigate = useNavigate();
  const [fieldHasBeenChanged, setFieldHasBeenChanged] = useState(false);

  const handleKeyPressEnter = () => {
    const isPageValidated = validatePage();
    if (isPageValidated) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };

  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, []);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.iflStep4Сoverage);
    sendAnalyticEvent(analyticEvents.iflFlatStep4Сoverage);

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  const risksArray = risks
    ?.filter((riskItem) => {
      if (riskItem.active) {
        return riskItem;
      }
    })
    .map((filteredRiskItem) => filteredRiskItem.code);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const useYupValidationResolver = (validationSchema: any) =>
    useCallback(
      async (data) => {
        try {
          const values = await validationSchema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors) {
          return {
            values: {},
            errors: (errors as any)?.inner?.reduce(
              (allErrors: any, currentError: ValidationError) => ({
                ...allErrors,
                [currentError.path as string]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [validationSchema]
    );

  const resolver = useYupValidationResolver(schema);

  const {
    control,
    formState: { isValid, errors },
    reset,
    trigger,
    getValues,
  } = useForm<Record<string, number>>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...defaults,
    },
  });

  const updateInsuranseSubproducts = async (code: string, value: number) => {
    setStepUpdated();
    const valid = await trigger(code);

    if (!valid) {
      return;
    }

    const updatedInsuranceSubproducts: InsuranceSubproducts = {
      ...insuranceSubproducts,
      subObjects:
        insuranceSubproducts?.subObjects.map((item) => {
          if (item.code === code) {
            return {
              ...item,
              defaultInsuranceSum: String(value),
            };
          }

          return item;
        }) || [],
      subObjectsDependencyScheme:
        insuranceSubproducts?.subObjectsDependencyScheme
          ? { ...insuranceSubproducts.subObjectsDependencyScheme }
          : {},
    };

    dispatch({
      type: IFLFlatActionTypes.SetInsuranceSubproducts,
      payload: updatedInsuranceSubproducts,
    });

    let totalSum = 0;

    updatedInsuranceSubproducts.subObjects.forEach(
      (subobject: any) => (totalSum += Number(subobject.defaultInsuranceSum))
    );

    dispatch({
      type: IFLFlatActionTypes.SetCurrentSum,
      payload: totalSum,
    });

    if (
      value > 0 &&
      !!updatedInsuranceSubproducts?.subObjectsDependencyScheme?.[code]
    ) {
      const values = getValues();

      if (
        updatedInsuranceSubproducts.subObjectsDependencyScheme[code].every(
          (dependencyName) => !values[dependencyName]
        )
      ) {
        dispatch({
          type: IFLFlatActionTypes.SetEmptyDependencies,
          payload: updatedInsuranceSubproducts.subObjectsDependencyScheme[code],
        });
        dispatch({
          type: IFLFlatActionTypes.SetFieldWithEmptyDependencies,
          payload: code,
        });
      }
    }

    if (
      value === 0 &&
      !!updatedInsuranceSubproducts?.subObjectsDependencyScheme
    ) {
      const values = getValues();

      const { fieldNameWithEmptyDependencies, emptyDependencyNames } =
        Object.keys(
          updatedInsuranceSubproducts?.subObjectsDependencyScheme
        ).reduce(
          (
            acc: {
              fieldNameWithEmptyDependencies?: string;
              emptyDependencyNames: string[];
            },
            fieldName
          ) => {
            const dependencies =
              updatedInsuranceSubproducts?.subObjectsDependencyScheme?.[
                fieldName
              ];

            if (
              values[fieldName] > 0 &&
              !!dependencies?.length &&
              dependencies.some((dependencyName) => dependencyName === code) &&
              dependencies.every((dependencyName) => !values[dependencyName])
            ) {
              return {
                fieldNameWithEmptyDependencies: fieldName,
                emptyDependencyNames: dependencies,
              };
            }

            return acc;
          },
          {
            fieldNameWithEmptyDependencies: undefined,
            emptyDependencyNames: [],
          }
        );

      if (!!emptyDependencyNames && !!fieldNameWithEmptyDependencies?.length) {
        dispatch({
          type: IFLFlatActionTypes.SetEmptyDependencies,
          payload: emptyDependencyNames,
        });
        dispatch({
          type: IFLFlatActionTypes.SetFieldWithEmptyDependencies,
          payload: fieldNameWithEmptyDependencies,
        });
      }
    }

    if (
      (value === 0 && code === fieldWithEmptyDependencies) ||
      (value > 0 && emptyDependencies?.some((name: string) => name === code))
    ) {
      dispatch({
        type: IFLFlatActionTypes.SetEmptyDependencies,
        payload: [],
      });
      dispatch({
        type: IFLFlatActionTypes.SetFieldWithEmptyDependencies,
        payload: undefined,
      });
    }
  };

  const { isLoading, error, res, refetch } = useRequest<InsuranceSubproducts>(
    'formIFLHooksGetSubproducts',
    'post',
    '/v1/references/get-subobjects',
    {
      productCode: selectedIProduct?.code,
      region: selectedRegion?.region,
      risks: risksArray,
    },
    [
      risks,
      getSubobjectsRefetchUid,
      selectedIProduct?.code,
      selectedRegion?.region,
      authTokens?.authorization?.accessToken,
    ],
    false,
    authTokens?.authorization?.accessToken
  );

  const {
    isFetching: isFetchingSumPerMonth,
    error: errorSumPerMonth,
    res: resSumPerMonth,
    refetch: refetchSumPerMonth,
  } = useRequest(
    'formIFLGetPrices',
    'post',
    '/v1/subscription/get-prices',
    {
      risks: risksArray,
      productCode: selectedIProduct?.code,
      insuranceSum: Number(currentSum),
      personProperties: {
        subObjects: subObjectConverter(insuranceSubproducts?.subObjects || []),
        address: {
          regionCode: selectedRegion?.region,
          address: dadaValue?.value || '',
          addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
        },
      },
      returnMinDuration: true,
      promoCode: promoCodeApplyed ? promoCodeApplyed : undefined,
    },
    [
      risks,
      selectedIProduct?.code,
      currentSum,
      selectedRegion?.region,
      dadaValue?.value,
      dadaValue?.data?.fias_id,
      promoCodeApplyed,
    ],
    !insuranceSubproducts?.subObjects
  );

  const validatePage = useCallback(
    () =>
      (!!fieldWithEmptyDependencies && !!emptyDependencies?.length) ||
      !currentSumErr,
    [currentSumErr, fieldWithEmptyDependencies, emptyDependencies]
  );

  const handleRefetch = () => {
    if (error) refetch();
    if (errorSumPerMonth) refetchSumPerMonth();
  };

  const descriptionNavHandler = () => {
    navigate('/flat-sum-descriptions');
  };

  useNextStep(validatePage);
  useIflFlatDraft();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (insuranceSubproducts?.subObjects && fieldHasBeenChanged) {
        refetchSumPerMonth();
        setFieldHasBeenChanged(false);
      }
    }, 250);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [insuranceSubproducts?.subObjects, fieldHasBeenChanged]);

  useEffect(() => {
    if (!insuranceSubproducts) {
      return;
    }

    let totalSum = 0;

    dispatch({
      type: IFLFlatActionTypes.SetCurrentSumMinLimit,
      payload:
        insuranceSubproducts.totalMinLimit ??
        selectedIProduct?.minProductLimit ??
        '0',
    });
    dispatch({
      type: IFLFlatActionTypes.SetCurrentSumMaxLimit,
      payload:
        insuranceSubproducts.totalMaxLimit ??
        selectedIProduct?.maxProductLimit ??
        '0',
    });

    defaults = {};
    schemaObj = {};

    insuranceSubproducts.subObjects.forEach((subobject) => {
      schemaObj[subobject.code] = yup
        .number()
        .typeError(t('COMMON:errors.mustEnterNumber') || '')
        .min(
          Number(subobject.minLimit),
          `${t(
            'COMMON:errors.amountNotLess'
          )} ${currencyRuLocaleWithoutFraction(Number(subobject.minLimit))} ₽`
        )
        .max(
          Number(subobject.maxLimit),
          `${t('COMMON:errors.amountNoMore')} ${currencyRuLocaleWithoutFraction(
            Number(subobject.maxLimit)
          )} ₽`
        )
        .integer(t('COMMON:errors.sumMustBeIntegerValue') || '')
        .required()
        .test(
          'In diapason?',
          t('COMMON:errors.amountCannotBeFrom') || '',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (value) => value! > 49_999 || value! < 1
        );

      totalSum += Number(subobject.defaultInsuranceSum);
      defaults[subobject.code] = Number(subobject.defaultInsuranceSum);
    });

    const newSchema = yup.object(schemaObj);
    setSchema(newSchema);

    dispatch({
      type: IFLFlatActionTypes.SetCurrentSum,
      payload: totalSum,
    });
    reset(defaults);
  }, []);

  const handleMergedSublimits = useCallback(
    (subObjects: InsuranceSubProduct[]) => {
      dispatch({
        type: IFLFlatActionTypes.SetInsuranceSubproducts,
        payload: {
          ...insuranceSubproducts,
          subObjects,
        },
      });
    },
    []
  );

  useMergeSublimits<InsuranceSubProduct>({
    firstSublimits: res?.subObjects,
    secondSublimits: insuranceSubproducts?.subObjects,
    handleMergedSublimits,
  });

  useEffect(() => {
    if (!isLoading && res && !insuranceSubproducts) {
      dispatch({
        type: IFLFlatActionTypes.SetInsuranceSubproducts,
        payload: res,
      });

      let totalSum = 0;

      dispatch({
        type: IFLFlatActionTypes.SetCurrentSumMinLimit,
        payload: res.totalMinLimit ?? selectedIProduct?.minProductLimit ?? '0',
      });
      dispatch({
        type: IFLFlatActionTypes.SetCurrentSumMaxLimit,
        payload: res.totalMaxLimit ?? selectedIProduct?.maxProductLimit ?? '0',
      });

      defaults = {};
      schemaObj = {};

      res.subObjects.forEach((subobject: InsuranceSubProduct) => {
        schemaObj[subobject.code] = yup
          .number()
          .typeError(t('COMMON:errors.mustEnterNumber') || '')
          .min(
            Number(subobject.minLimit),
            `${t(
              'COMMON:errors.amountNotLess'
            )} ${currencyRuLocaleWithoutFraction(Number(subobject.minLimit))} ₽`
          )
          .max(
            Number(subobject.maxLimit),
            `${t(
              'COMMON:errors.amountNoMore'
            )} ${currencyRuLocaleWithoutFraction(Number(subobject.maxLimit))} ₽`
          )
          .integer(t('COMMON:errors.sumMustBeIntegerValue') || '')
          .required()
          .test(
            'In diapason?',
            t('COMMON:errors.amountCannotBeFrom') || '',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (value) => value! > 49_999 || value! < 1
          );

        totalSum += Number(subobject.defaultInsuranceSum);
        defaults[subobject.code] = Number(subobject.defaultInsuranceSum);
      });

      const newSchema = yup.object(schemaObj);
      setSchema(newSchema);

      dispatch({
        type: IFLFlatActionTypes.SetCurrentSum,
        payload: totalSum,
      });
      reset(defaults);
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (currentSum > 0) {
      dispatch({
        type: IFLFlatActionTypes.SetInsuranceSum,
        payload: Number(currentSum),
      });
    }
  }, [currentSum]);

  useEffect(() => {
    dispatch({
      type: IFLFlatActionTypes.SetCurrentSumErr,
      payload: !numBetween(
        currentSum,
        Number(currentSumMinLimit),
        Number(currentSumMaxLimit),
        true
      ),
    });
  }, [currentSum, currentSumMinLimit, currentSumMaxLimit]);

  useEffect(() => {
    const disableNav =
      (!!fieldWithEmptyDependencies && !!emptyDependencies?.length) ||
      currentSumErr ||
      !isValid ||
      isFetchingSumPerMonth;

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: disableNav,
    });
  }, [
    currentSumErr,
    currentStep,
    fieldWithEmptyDependencies,
    emptyDependencies,
    isValid,
    isFetchingSumPerMonth,
  ]);

  if (isLoading) return <Skeleton />;

  if (error && error?.response?.data?.code === 'USER_DATA_ERROR') {
    navigate('/score-error');
  }

  if (
    errorSumPerMonth &&
    errorSumPerMonth?.response?.data?.code === 'OBJECT_DATA_ERROR'
  ) {
    dispatch({
      type: IFLFlatActionTypes.SetIsObjectDataError,
      payload: true,
    });

    navigate('/score-error');
  }

  if (
    (error && error?.response?.data?.code !== 'USER_DATA_ERROR') ||
    (errorSumPerMonth &&
      errorSumPerMonth?.response?.data?.code !== 'VALIDATION_ERROR' &&
      errorSumPerMonth?.response?.data?.code !== 'BUSINESS_ERROR' &&
      errorSumPerMonth?.response?.data?.code !== 'TECHNICAL_ERROR' &&
      errorSumPerMonth?.response?.data?.code !== 'OBJECT_DATA_ERROR')
  )
    return (
      <GlobalErrorInfo pending={isLoading} retrayHandler={handleRefetch} />
    );

  if (!isLoading && !res)
    return (
      <div>
        <h3>{t('COMMON:labels.listEmpty')}</h3>
      </div>
    );

  return (
    <Container>
      <AdaptiveHeadersWrapper>
        <HeaderAdaptive1WithSubTitle>
          {t('COMMON:headers.selectCoverageAmounts')}
        </HeaderAdaptive1WithSubTitle>
        <HeaderAdaptive5>
          {t('COMMON:hints.determineMaximumPayout')}
        </HeaderAdaptive5>
      </AdaptiveHeadersWrapper>
      <AdaptiveSliderListWrapper>
        {insuranceSubproducts?.subObjects.map((item: InsuranceSubProduct) => (
          <Controller
            key={`${item.code}-key`}
            name={item.code}
            control={control}
            render={({ field }) => (
              <MoneyRangeInput
                hasTooltip
                isPriceFormatted
                id={item.code}
                label={item.name}
                value={Number(item.defaultInsuranceSum) || 0}
                min={Number(item.minLimit) || 0}
                max={Number(item.maxLimit) || 0}
                step={Number(item.step) || 0}
                tooltipText={item.tooltip || item.description}
                onChange={(value: number) => {
                  field.onChange(value);
                  setFieldHasBeenChanged(true);
                  updateInsuranseSubproducts(item.code, value);
                  sendAnalyticEvent(analyticEvents.coverageSumChangeRealty, {
                    coverage_type: item.name,
                  });
                }}
                error={
                  !!errors[item.code]?.message ||
                  item.code === fieldWithEmptyDependencies
                }
                helperErrorText={
                  (item.code === fieldWithEmptyDependencies
                    ? t('COMMON:hints.civilLiabilityInsured', {
                        name: item.name,
                        canNotBe:
                          item.name === t('COMMON:hints.civilResponsibility')
                            ? t('COMMON:hints.cannotBeInsured')
                            : t('COMMON:hints.theyCannotBeInsured'),
                      }) || ''
                    : undefined) || errors[item.code]?.message
                }
                warning={emptyDependencies?.some((name) => name === item.code)}
              />
            )}
          />
        ))}
      </AdaptiveSliderListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutCategories') || ''}
        />
      </LinkContainer>
      {insuranceSubproducts?.subObjects && (
        <AdaptivePerMonthFooter>
          <SumWrapper>
            <TotalSum
              title={`${t('COMMON:labels.coverAmount')}:`}
              totalSum={numFormat(currentSum)}
              isError={currentSumErr}
              subtitleText={
                `${t('COMMON:labels.from')} ${numFormat(
                  Number(currentSumMinLimit)
                )} ₽ ` +
                `${t('COMMON:labels.to')} ${numFormat(
                  Number(currentSumMaxLimit)
                )} ₽`
              }
            />
          </SumWrapper>
          <SumWrapper>
            <IflSumPerMonthContainer>
              {(currentSum || currentSum === 0) &&
                insuranceSubproducts?.subObjects && (
                  <IflSumPerMonth
                    isLoading={isFetchingSumPerMonth || !!errorSumPerMonth}
                    disabled={isLoading}
                    sumPerMonth={Math.ceil(
                      Number(resSumPerMonth?.prices[0].premiumAndDelta)
                    )}
                    sumPromoPerMonth={
                      resSumPerMonth?.prices[0]?.premiumAndDeltaPromo &&
                      Math.ceil(
                        Number(resSumPerMonth?.prices[0].premiumAndDeltaPromo)
                      )
                    }
                  />
                )}
              {errorSumPerMonth && (
                <IflSumPerMonthError
                  errorResponse={errorSumPerMonth}
                  currentSumErr={
                    currentSumErr &&
                    (currentSum < Number(currentSumMinLimit) ? 'less' : 'gt')
                  }
                />
              )}
            </IflSumPerMonthContainer>
          </SumWrapper>
        </AdaptivePerMonthFooter>
      )}
    </Container>
  );
};
