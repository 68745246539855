import type { AuthFlowStoreState } from '@src/store/auth-flow';
import { initAuthFlowStoreState } from '@src/store/auth-flow';
import type { AuthStoreState } from '@src/store/authorize';
import { initAuthStoreState } from '@src/store/authorize';
import type { DraftStoreState } from '@src/store/draft';
import { initDraftStoreState } from '@src/store/draft';
import type { IFLFlatStoreState } from '@src/store/ifl-flat';
import { initIFLFlatStoreState } from '@src/store/ifl-flat';
import type { IFLHouseStoreState } from '@src/store/ifl-house';
import { initIFLHouseStoreState } from '@src/store/ifl-house';
import type { MiteStoreState } from '@src/store/mite';
import { initMiteStoreState } from '@src/store/mite';
import type { NSStoreState } from '@src/store/ns';
import { initNSStoreState } from '@src/store/ns';
import type { OrderStoreState } from '@src/store/order';
import { initOrderStoreState } from '@src/store/order';
import type { PetsStoreState } from '@src/store/pets';
import { initPetsStoreState } from '@src/store/pets';
import type { PresetSettingStoreState } from '@src/store/preset-setting';
import { initPresetSettingStoreState } from '@src/store/preset-setting';
import type { SportNSStoreState } from '@src/store/sport/index';
import { initSportNSStoreState } from '@src/store/sport/sport-ns-store.state';
import type { UserStoreState } from '@src/store/user';
import { initUserStoreState } from '@src/store/user';
import type { WizardStoreState } from '@src/store/wizard';
import { initWizardStoreState } from '@src/store/wizard';

export type StoreState = {
  stateFormIFLHouse: IFLHouseStoreState;
  stateFormIFLFlat: IFLFlatStoreState;
  stateMite: MiteStoreState;
  stateFormNS: NSStoreState;
  stateWizard: WizardStoreState;
  stateAuth: AuthStoreState;
  stateUser: UserStoreState;
  stateOrder: OrderStoreState;
  stateFormPets: PetsStoreState;
  stateFormNSSport: SportNSStoreState;
  stateDraft: DraftStoreState;
  stateAuthFlow: AuthFlowStoreState;
  statePresetSetting: PresetSettingStoreState;
};

export const initStoreState: StoreState = {
  stateFormIFLHouse: { ...initIFLHouseStoreState },
  stateFormIFLFlat: { ...initIFLFlatStoreState },
  stateMite: { ...initMiteStoreState },
  stateFormNS: { ...initNSStoreState },
  stateWizard: { ...initWizardStoreState },
  stateAuth: { ...initAuthStoreState },
  stateUser: { ...initUserStoreState },
  stateOrder: { ...initOrderStoreState },
  stateFormPets: { ...initPetsStoreState },
  stateFormNSSport: { ...initSportNSStoreState },
  stateDraft: { ...initDraftStoreState },
  stateAuthFlow: { ...initAuthFlowStoreState },
  statePresetSetting: { ...initPresetSettingStoreState },
};
