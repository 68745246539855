/* eslint-disable prettier/prettier */
import { useContext} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Skeleton } from '@src/components';
import { UseQueryStatus } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useAuthFlow, useSetPresetDataInStore } from '@src/hooks';
import { SubscriptionChoice } from '@src/pages/authorization';
import { Store } from '@src/store';

export const RoutesNavigate = () => {
  const {
    state: {
      stateUser: { profile },
    },
  } = useContext(Store);


  const [searchParams] = useSearchParams();
  const product = searchParams.get('product');

  const navigate = useNavigate();
  const { isLoading, hasError, path } = useAuthFlow();

  const {
    res: resPresetData,
    isLoading: isPresetDataLoading,
    isError: isPresetDataError,
    status,
  } = useSetPresetDataInStore();

  const pathToLogin =
    resPresetData && !(profile?.profile?.firstName && product)
      ? '/login'
      : product ? `${product}` : '';

  const currentPath = path || pathToLogin;

  if (currentPath && !(UseQueryStatus.LOADING === status)) {
    navigate(currentPath);
  }

  if (isLoading || isPresetDataLoading) {
    return <Skeleton />;
  }

  if (hasError || isPresetDataError) {
    return <GlobalErrorInfo />;
  }

  return <SubscriptionChoice />;
};
